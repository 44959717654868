import React from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import moment from "moment";
import {CalendarToday} from "@mui/icons-material";

interface OpportunityCardProps {
  logo: string;
  title: string;
  amount: string;
  tags: string[];
  financier: string
  financier_id: string
  description: string;
  deadline: string | boolean;
  location: string;
  financing_type: string;
}

const stripHTML = (html: any) => {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = html;
  return tempDiv.innerText || tempDiv.textContent || '';
}


const OpportunityCard: React.FC<OpportunityCardProps> = ({
                                                           logo,
                                                           title,
                                                           amount,
                                                           tags,
                                                           description,
                                                           deadline,
                                                           financier,
                                                           financier_id,
                                                           location,
                                                           financing_type
                                                         }) => {

  const maxLength = 100
  const plaintext = stripHTML(description)
  const displayText = plaintext.length > maxLength ? `${plaintext.slice(0, maxLength)}...` : plaintext

  var formattedDate = "";
  var formattedTime = "";

  if (typeof deadline == "string"){
    const deadlineDate = moment(deadline, "YYYY-MM-DD HH:mm:ss")
    formattedDate = deadlineDate.format("dddd, MMMM DD, YYYY")
    formattedTime = deadlineDate.format("h:mm A")
  }

  return (
      <div className="card mb-3 border border-9 border-gray-300">
        <div className="card-body">
          <div className="row mb-3">

            <div className="col-12">
              <p className="fw-bold mb-0">{title}</p>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="text-dark mb-4">
                {displayText}
              </div>
            </div>
          </div>

          <div className="row">
            {financier && <div className="col-12 mb-3">
              <div className="w-100 bg-light border border-9 border-gray-300 rounded p-2">
                <div className="row d-flex">
                  <div className="col-8">
                    <div className="text-muted small">
                      {financing_type == "grant" ? "Grant Provider" : "Financier"}
                    </div>
                    <p className="mb-0">{financier}</p>
                  </div>
                  <div className="col-4">
                    <img src={`data:image/jpeg;base64, ${logo}`} alt="logo" className="w-100 rounded"
                         style={{height: "auto"}}/>
                  </div>
                </div>

              </div>
            </div>}
            {amount && <div className="col-12 mb-3">
              <div className="w-100 bg-light border border-9 border-gray-300 rounded p-2">
                <div className="text-muted small">
                  Amount
                </div>
                <p className="mb-0">{Number(amount).toLocaleString()}</p>
              </div>
            </div>}
            {tags && <div className="col-12 mb-3">
              <div className="w-100 bg-light border border-9 border-gray-300 rounded p-2">
                <div className="text-muted small">
                  Sectors
                </div>
                <p className="mb-0">
                  {tags.map((tag, index) => (
                      <span key={index} className="badge bg-success me-2">
                                    {tag}
                                </span>
                  ))}
                </p>
              </div>
            </div>}
            <div className="col-12">
              <div className="w-100 bg-light border border-9 border-gray-300 rounded p-2">
                <div className="row d-flex align-items-center">
                  <div className="col-9">
                    <div className="text-muted small">
                      Deadline
                    </div>
                    <p className="mb-0">{typeof deadline == "string" ? formattedDate : "Open"}<br/>{typeof deadline == "string" ? formattedTime : "\u00A0"}</p>
                  </div>

                  <div className="col-3 text-end">
                    <CalendarToday  className=" me-2" fontSize="large" />
                  </div>
                </div>

              </div>
            </div>
          </div>

        </div>

      </div>
  );
};

export default OpportunityCard;
