import * as React from "react";
import {Link} from "react-router-dom";
import OpportunityCard from "./OpportunityCard";
import {useEffect, useState} from "react";
import {Spinner} from "react-bootstrap";

export default function OpportunitiesList() {

  const [jobs, setJobs] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchCalls = async () => {
      try {
        const response = await fetch('https://test.financiers.black.asigmagroup.com/api/v1/calls/');
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();

        setJobs(data.result.calls); // Update state with the fetched data
        setIsLoading(false);
      } catch (error: any) {
        setError(error.message); // Handle errors
        setIsLoading(false);
      }
    };

    fetchCalls();

  }, []);

  if (isLoading) {
    return (
        <div className="text-center pt-5 vh-100">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
    );
  }


  if (error) {
    return <div className="text-center text-danger pt-5">Error: {error}</div>;
  }

  return (
      <section
          className="container-fluid py-4"
          style={{backgroundColor: "#F5F5F5"}}
          id="value"
      >
        <div className="row">
          <div className="col-12">
            <div className="container">
              <div className="row align-items-center">
                {jobs.map((job: any, index) => (
                    <div key={index} className="col-12 col-lg-4">
                      <Link
                          to={`https://issho.asigmagroup.com/${job.url}`}
                          style={{textDecoration: "none", color: "inherit"}}
                      >
                        <OpportunityCard
                            financier={job.financier}
                            financier_id={job.financier_id}
                            logo={job.logo}
                            title={job.name}
                            amount={job.call_amount}
                            tags={job.sectors}
                            description={job.description}
                            deadline={job.deadline}
                            location=""
                            financing_type={job.financing_type}
                        />
                      </Link>
                    </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
  );
}